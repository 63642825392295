import { useEffect, useState } from "react";
import MyChartCompare from "./MyChartCompare";
import GraphContainer from "./GraphContainer";

export default function Trailing13MonthsBar({
	measuredData,
	expectedData,
	asBuiltData,
	removeGraph,
	expandedByDefault,
	allowResize = true,
	hasAsBuilt = false,
	isPublicStorage = false,
}) {
	const [measuredFiltered, setMeasuredFiltered] = useState([]);
	const [expectedFiltered, setExpectedFiltered] = useState([]);
	const [asBuiltFiltered, setAsBuiltFiltered] = useState([]);
	const [generatedTotal, setGeneratedTotal] = useState(0);
	const [selection, setSelection] = useState({
		measured: true,
		expected: true,
		asBuilt: false,
	});

	useEffect(() => {
		const currentDate = new Date();
		const thirteenMonthsAgo = new Date(currentDate);
		thirteenMonthsAgo.setMonth(currentDate.getMonth() - 12);

		const newMeasuredFiltered = measuredData.filter((item) => {
			const date = new Date(item.Date);
			return date >= thirteenMonthsAgo && date <= currentDate;
		});

		const newExpectedFiltered = expectedData.filter((item) => {
			const date = new Date(item.Date);
			return date >= thirteenMonthsAgo && date <= currentDate;
		});

		const newAsBuiltFiltered = asBuiltData.filter((item) => {
			const date = new Date(item.Date);
			return date >= thirteenMonthsAgo && date <= currentDate;
		});

		setMeasuredFiltered(newMeasuredFiltered);
		setExpectedFiltered(newExpectedFiltered);
		setAsBuiltFiltered(newAsBuiltFiltered);

		// const totalGenerated = newMeasuredFiltered.reduce((acc, obj) => acc + obj.kWh, 0);
		// setGeneratedTotal(totalGenerated);
	}, [measuredData, expectedData, asBuiltData]);

	return (
		<GraphContainer
			title={`Trailing 13 Months`}
			removeGraph={removeGraph}
			selection={selection}
			setSelection={setSelection}
			expandedByDefault={expandedByDefault}
			allowResize={allowResize}
			hasAsBuilt={hasAsBuilt}
			isPublicStorage={isPublicStorage}>
			<MyChartCompare
				measuredData={measuredFiltered}
				expectedData={expectedFiltered}
				asBuiltData={asBuiltFiltered}
				period="trailing13months"
				selection={selection}
				isPublicStorage={isPublicStorage}
			/>
		</GraphContainer>
	);
}
