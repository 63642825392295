import { useEffect, useState } from "react";
import GraphContainer from "./GraphContainer";
import MyChartCompare from "./MyChartCompare";

export default function Trailing30Days({ measuredData, expectedData, asBuiltData, hasAsBuilt = false, isPublicStorage = false }) {
	const [measuredFiltered, setMeasuredFiltered] = useState([]);
	const [expectedFiltered, setExpectedFiltered] = useState([]);
	const [asBuiltFiltered, setAsBuiltFiltered] = useState([]);
	const [selection, setSelection] = useState({
		measured: true,
		expected: true,
		asBuilt: false,
	});

	useEffect(() => {
		const endDate = new Date();
		const startDate = new Date();
		startDate.setDate(endDate.getDate() - 29);

		const dateArray = [];
		let currentDate = new Date(startDate);
		while (currentDate <= endDate) {
			dateArray.push(new Date(currentDate));
			currentDate.setDate(currentDate.getUTCDate() + 1);
		}

		const aggregatedExpected = {};
		const aggregatedMeasured = {};
		const aggregatedAsBuilt = {};

		dateArray.forEach((date) => {
			const key = date.toISOString().split("T")[0];
			aggregatedExpected[key] = 0;
			aggregatedMeasured[key] = 0;
			aggregatedAsBuilt[key] = 0;
		});

		expectedData.forEach((item) => {
			const dateKey = new Date(item.Date).toISOString().split("T")[0];
			if (dateKey in aggregatedExpected) {
				aggregatedExpected[dateKey] += item.kWh;
			}
		});

		measuredData.forEach((item) => {
			const dateKey = new Date(item.Date).toISOString().split("T")[0];
			if (dateKey in aggregatedMeasured) {
				aggregatedMeasured[dateKey] += item.kWh;
			}
		});

		asBuiltData.forEach((item) => {
			const dateKey = new Date(item.Date).toISOString().split("T")[0];
			if (dateKey in aggregatedAsBuilt) {
				aggregatedAsBuilt[dateKey] += item.kWh;
			}
		});

		const newExpectedFiltered = Object.keys(aggregatedExpected).map((key) => ({
			Date: key,
			kWh: aggregatedExpected[key],
		}));

		const newMeasuredFiltered = Object.keys(aggregatedMeasured).map((key) => ({
			Date: key,
			kWh: aggregatedMeasured[key],
		}));

		const newAsBuiltFiltered = Object.keys(aggregatedAsBuilt).map((key) => ({
			Date: key,
			kWh: aggregatedAsBuilt[key],
		}));

		setExpectedFiltered(newExpectedFiltered);
		setMeasuredFiltered(newMeasuredFiltered);
		setAsBuiltFiltered(newAsBuiltFiltered);
	}, [expectedData, measuredData, asBuiltData]);

	return (
		<>
			<GraphContainer
				title={`Trailing 30 days`}
				selection={selection}
				setSelection={setSelection}
				expandedByDefault={false}
				hasAsBuilt={hasAsBuilt}
				isPublicStorage={isPublicStorage}>
				<div className="">
					<MyChartCompare
						measuredData={measuredFiltered}
						expectedData={expectedFiltered}
						period="day"
						asBuiltData={asBuiltFiltered}
						selection={selection}
						isPublicStorage={isPublicStorage}
					/>
				</div>
			</GraphContainer>
		</>
	);
}
